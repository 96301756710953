/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateDatumById = /* GraphQL */ `
  subscription OnUpdateDatumById($id: ID!) {
    onUpdateDatumById(id: $id) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDatumByNodeId = /* GraphQL */ `
  subscription OnUpdateDatumByNodeId($nodeId: ID!) {
    onUpdateDatumByNodeId(nodeId: $nodeId) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOldDatumByDatumId = /* GraphQL */ `
  subscription OnCreateOldDatumByDatumId($datumId: ID!) {
    onCreateOldDatumByDatumId(datumId: $datumId) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOldDatumByDatumId = /* GraphQL */ `
  subscription OnUpdateOldDatumByDatumId($datumId: ID!) {
    onUpdateOldDatumByDatumId(datumId: $datumId) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEccMenu = /* GraphQL */ `
  subscription OnCreateEccMenu {
    onCreateEccMenu {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEccMenu = /* GraphQL */ `
  subscription OnUpdateEccMenu {
    onUpdateEccMenu {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEccMenu = /* GraphQL */ `
  subscription OnDeleteEccMenu {
    onDeleteEccMenu {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNodeType = /* GraphQL */ `
  subscription OnCreateNodeType {
    onCreateNodeType {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNodeType = /* GraphQL */ `
  subscription OnUpdateNodeType {
    onUpdateNodeType {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNodeType = /* GraphQL */ `
  subscription OnDeleteNodeType {
    onDeleteNodeType {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant {
    onCreateTenant {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant {
    onUpdateTenant {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant {
    onDeleteTenant {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkHistory = /* GraphQL */ `
  subscription OnCreateWorkHistory {
    onCreateWorkHistory {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkHistory = /* GraphQL */ `
  subscription OnUpdateWorkHistory {
    onUpdateWorkHistory {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkHistory = /* GraphQL */ `
  subscription OnDeleteWorkHistory {
    onDeleteWorkHistory {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAgvPosition = /* GraphQL */ `
  subscription OnCreateAgvPosition {
    onCreateAgvPosition {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAgvPosition = /* GraphQL */ `
  subscription OnUpdateAgvPosition {
    onUpdateAgvPosition {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAgvPosition = /* GraphQL */ `
  subscription OnDeleteAgvPosition {
    onDeleteAgvPosition {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDatumType = /* GraphQL */ `
  subscription OnCreateDatumType {
    onCreateDatumType {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDatumType = /* GraphQL */ `
  subscription OnUpdateDatumType {
    onUpdateDatumType {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDatumType = /* GraphQL */ `
  subscription OnDeleteDatumType {
    onDeleteDatumType {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDatum = /* GraphQL */ `
  subscription OnCreateDatum {
    onCreateDatum {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDatum = /* GraphQL */ `
  subscription OnUpdateDatum {
    onUpdateDatum {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDatum = /* GraphQL */ `
  subscription OnDeleteDatum {
    onDeleteDatum {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventlog = /* GraphQL */ `
  subscription OnCreateEventlog {
    onCreateEventlog {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventlog = /* GraphQL */ `
  subscription OnUpdateEventlog {
    onUpdateEventlog {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventlog = /* GraphQL */ `
  subscription OnDeleteEventlog {
    onDeleteEventlog {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNodeSecret = /* GraphQL */ `
  subscription OnCreateNodeSecret {
    onCreateNodeSecret {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNodeSecret = /* GraphQL */ `
  subscription OnUpdateNodeSecret {
    onUpdateNodeSecret {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNodeSecret = /* GraphQL */ `
  subscription OnDeleteNodeSecret {
    onDeleteNodeSecret {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNode = /* GraphQL */ `
  subscription OnCreateNode {
    onCreateNode {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNode = /* GraphQL */ `
  subscription OnUpdateNode {
    onUpdateNode {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNode = /* GraphQL */ `
  subscription OnDeleteNode {
    onDeleteNode {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOldDatum = /* GraphQL */ `
  subscription OnCreateOldDatum {
    onCreateOldDatum {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOldDatum = /* GraphQL */ `
  subscription OnUpdateOldDatum {
    onUpdateOldDatum {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOldDatum = /* GraphQL */ `
  subscription OnDeleteOldDatum {
    onDeleteOldDatum {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOldDatumHourly = /* GraphQL */ `
  subscription OnCreateOldDatumHourly {
    onCreateOldDatumHourly {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOldDatumHourly = /* GraphQL */ `
  subscription OnUpdateOldDatumHourly {
    onUpdateOldDatumHourly {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOldDatumHourly = /* GraphQL */ `
  subscription OnDeleteOldDatumHourly {
    onDeleteOldDatumHourly {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOldDatumDaily = /* GraphQL */ `
  subscription OnCreateOldDatumDaily {
    onCreateOldDatumDaily {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOldDatumDaily = /* GraphQL */ `
  subscription OnUpdateOldDatumDaily {
    onUpdateOldDatumDaily {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOldDatumDaily = /* GraphQL */ `
  subscription OnDeleteOldDatumDaily {
    onDeleteOldDatumDaily {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOperatingRatio = /* GraphQL */ `
  subscription OnCreateOperatingRatio {
    onCreateOperatingRatio {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOperatingRatio = /* GraphQL */ `
  subscription OnUpdateOperatingRatio {
    onUpdateOperatingRatio {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOperatingRatio = /* GraphQL */ `
  subscription OnDeleteOperatingRatio {
    onDeleteOperatingRatio {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
